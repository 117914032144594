/* FOOTER
==============================================================*/
footer {
    position: relative;
    overflow: hidden;
}

.footer-pattern {
    background: url(/images/shell/seed-texture-dark.svg) repeat-x;
    position: absolute;
    width: 100%;
    top: -20px;
    height: 100px;
}

.footer-wrap {
    background: rgba(79,78,77,.9);
    padding: 0 0 0 70px;
    box-sizing: border-box;
}

.footer-left {
    display: inline-block;
    *display: inline;
    zoom: 1;
    width: 40%;
    vertical-align: top;
    padding-top: 100px;
    color: #fff;
    font-size: 13px;
    margin-bottom: 50px;
}

.footer-left a {
    color: #e8a348;
    font-weight: bold;
    font-size: 18px;
}
.footer-left ul {
    margin: 20px 0 0 0;
}

.footer-left ul li {
    display: inline-block;
    *display: inline;
    zoom: 1;
    width: 30px;
    margin-bottom: 0;
}

.footer-left ul li img {
    width: 100%;
    display: block;
}

.footer-left a:hover img {
    opacity: .7;
}

.footer-contact {
    position: relative;
}

.footer-contact::before {
    content: url(/images/envelope.png);
    display: block;
    position: absolute;
    left: -40px;
    top: 5px;
}

.footer-middle {
    display: inline-block;
    *display: inline;
    zoom: 1;
    width: 50%;
    vertical-align: top;
    text-align: right;
    box-sizing: border-box;
    padding-right: 50px;
    padding-top: 100px;
}

.footer-middle .logos {

    margin-top: 2em;
}

.footer-middle .logos img {
    align-self: center;
    margin-left: 2em;


    /*flex-grow: 1*/
}
.footer-middle .logos a {
    text-transform: none;
}
.footer-middle .bornBred {
    margin-top: -2em;
}

.footer-middle .corteva-logo p {
    margin-top: -0.5em;
}

.footer-middle ul {
    margin-bottom: 20px;
}

.footer-middle ul li {
    display: inline-block;
    *display: inline;
    zoom: 1;
    margin: 0 0 0 25px;
}

.footer-middle a {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
}

.footer-middle a:hover {
    color: #e8a348;
}

p.legal {
    color: #fff;
    font-size: 12px;
}

.legal .legallarge{
    font-size: 20px;
    padding: 4px;
    line-height: 1px;
    position: relative;
    top: 6px;
}

.footer-right {
    display: inline-block;
    *display: inline;
    zoom: 1;
    width: 10%;
    vertical-align: top;
    background: #4f4e4d;
    padding: 100px 10px 120px;
    box-sizing: border-box;
    text-align: center;
    height: 400px;
}

.footer-right img {
    width: 50%;
}

@media only screen and (max-width: 768px) {
    .footer-contact address {
        width: 80% !important;
        display: block;
        margin: 0 auto;
        line-height: 22px;
    }
}


@media only screen and (min-width: 1150px) and (max-width: 1369px) {
    .footer-left {
        width: 30%;
    }
    .footer-middle {
        width: 60%;
    }
    .footer-left span {
        display: none;
    }
    .footer-left phone {
        display: block;
    }
    .footer-right {
        padding: 80px 10px 180px;
    }
}

@media only screen and (max-width: 1149px) {
    .footer-pattern {
        background: url(/images/shell/seed-texture-dark.svg) repeat-x;
        position: absolute;
        width: 100%;
        top: -40px;
        height: 100px;
        background-size: 120%;
    }
    
    .footer-wrap {
        padding: 0;
    }
    
    .footer-left {
        display: block; 
        text-align: center;
        width: 100%;
    }
    .footer-middle {
       display: block; 
        text-align: center;
        width: 100%;
    }
    .footer-left span {
        display: none;
    }
    .footer-left phone {
        display: block;
    }
    .footer-right {
        display: block; 
        text-align: center;
        width: 100%;
        padding: 20px 0;
        height: 100px;
    }
    
    .footer-right img {
        width: 30px;
    }
    
    .footer-contact::before {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .footer-pattern {
        background: url(/images/shell/seed-texture-dark.svg) repeat-x;
        position: absolute;
        width: 100%;
        top: 0px;
        height: 100px;
        background-size: 120%;
    }
}

@media only screen and (max-width: 767px) {
    .footer-pattern {
        background: url(/images/shell/seed-texture-dark.svg) repeat-x;
        position: absolute;
        width: 100%;
        top: -40px;
        height: 100px;
        background-size: 300%;
    }
    .footer-left {
        padding-top: 75px;
    }
    .footer-middle {
        text-align: center;
        padding: 0 15px;
    }
    
    .footer-middle ul {
        margin: 0;
    }
    
    .footer-middle ul li {
        display: block;
        width: 100%;
        margin: 0;
    }
    
}

@media only screen and (max-width: 479px) {
    .footer-pattern {
        background-size: 450%;
    }
}

@media only screen and (min-width:768px) and (max-width: 1149px) {
    .footer-middle {
        text-align: center;
        padding: 0 100px;
    }
}
@media only screen and (max-width: 1149px) {
    .footer-middle .logos img {
        margin-left:0;
        margin-bottom: 0.5em;
    }
}