// Call to Action

// Copy

.cb-copy {
   padding-top: 80px;
   padding-bottom: 80px;
   &:nth-child(odd) {
        .pattern {
            left:0;
        }
    }
    &:nth-child(even) {
        .pattern {
            right:0;
        }
    }
}

// Copy Image Grid

.column-img {
    display: inline-block;
    *display: inline;
    zoom: 1;
    width: 50%;
    vertical-align: middle;
    img {
       display: block;
       margin: 0;
       width: 100%;
   }
}

.column-content {
    display: inline-block;
    *display: inline;
    zoom: 1;
    width: 50%;
    vertical-align: middle;
    box-sizing: border-box;
    padding: 0 100px;
    h3 {
        color: #fff;
        font-size: 32px;
    }
    p {
        color: #fff;
    }
    a {
       font-family: 'GothamRounded', sans-serif; 
        font-weight: bold;
        text-transform: uppercase;
        font-size: 15px;
        color: #fff;
        -webkit-transition: all 500ms ease-in-out;
        -moz-transition: all 500ms ease-in-out;
        -ms-transition: all 500ms ease-in-out;
        -o-transition: all 500ms ease-in-out;
        transition: all 500ms ease-in-out;
        span {
            font-size: 21px;
        }
        a:hover {
            opacity: .5;
        }
    }
}

// Copy with Full Bleed Image 
.cb-copyOnFullBleedImage {
    position: relative;
    text-align: center;
    background-position: 50% 50% !important;
    background-attachment: fixed !important;
}

.fullBleedOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
}

// Testimonial Block

.testimonial-optionTwo {
    .content-testimonial {
        margin-left: 228px!important;
        @media only screen and (min-width: 960px) and (max-width: 1259px) {
            margin-left: 174px!important;
        }
        @media only screen and (min-width: 768px) and (max-width: 959px) {
            margin-left: 144px!important;
        }
        @media only screen and (max-width: 767px) {
            margin-left: 0!important;
        }
    }
    .testimonial-video {
        width: 100%;
        left: 0;
        margin-bottom: 40px;
    }
    &.testimonial-video-block .testimonial-insert {
        width: 100%;
    }
}

.testimonial-optionThree {
    .testimonial-video {
        @media only screen and (max-width: 1259px) {
            width: 100%;
            left: 0;
            margin-bottom: 40px;
        }
        @media only screen and (min-width: 960px) and (max-width: 1259px) {
            display: block;
            width: 600px;
            margin-right: auto;
            margin-left: auto;
        }
    }
}


// Content with Sidebar
.content-wrapper {
    .cb-copy:not(.interstitial){
        padding-top: 0;
    }
    .testimonial-interstitial {
        padding:0;
    }
    .testimonial-optionTwo {
        .content-testimonial {
            margin-left: 0!important;
        }
    }
    .testimonial-optionThree {
        margin-bottom: 70px;
        .testimonial-container {
            margin-left: 0!important;
        }
        .testimonial-content {
            @media only screen and (min-width: 768px) {
                width: 385px;
            }
        }
    }
}

// Forms
.cb-copy + .cb-form {
    margin-top:-60px;
}
.cb-form {
    label.required {
      &::after {
        content: "*";
        margin-left: 5px;
        color: red;
      }
    }
    .alert {
        margin-bottom: 15px;
    }
    .errors {
        color: #ca4633;
        &.help-block {
            margin-bottom: 0;
        }
    }
}
.col.m12.s12 {
    margin-bottom: 0!important;
    .input-group-one-line p {
        margin-bottom:0;
    }
}