/* GENERAL STYLES
==============================================================*/

h2 {
   font-size: 42px;
}
h3 {
   font-size: 32px;
}
h2, h3 {
   color: #e8a348;
   text-transform: uppercase;
}

.site-wrapper {
   overflow: hidden;
}

.videoWrapper {
   position: relative;
   padding-bottom: 56.25%; /* 16:9 */
   padding-top: 0;
   height: 0;
}
.videoWrapper iframe {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}