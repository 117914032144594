.footer-navigation ul {
   display: block;
   list-style-type: none;
   margin: 0;
   padding: 0; 
}
.footer-navigation li {
   border-top: none;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
   display: inline-block;
   font-size: 15px;
   margin: 0 0 10px 0;
   padding: 0;
   position: relative;
   text-align: left;
   vertical-align: top;
   width: 48%;
}