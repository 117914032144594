/* HOME HERO
==============================================================*/

.home-hero {
    position: relative;
}

.down {
    position: absolute;
    bottom: 50px;
    text-align: center;
    width: 100%;
    z-index: 555;
}
.chevron-down {
    width:25px;
    vertical-align: -.25rem;
}

.down i {
    width: 200px;
}
.overlay {
   position: absolute;
    background: rgba(0,0,0,.3);
    width: 100%;
    height: 100%;
}











.hero-content {
    background: rgba(0,0,0,.3);
    box-sizing: border-box;
    width: 100%;
    height: 90vh;
    z-index: 9999;
    text-align:center;



    
}

.hero-content.no-overlay {
    background: transparent;
}

.home-hero .slides  {
    height: 90vh;
    background: #e1e1e1;
}

.home-hero .slides li {
    height: 90vh;
}

.hero-content h1 {
    flex-direction: column;
    position: relative;
    border: 6px solid black;
    background: rgba(255, 255, 255, 0.6);
    padding-left: 1.1em;
    padding-right: 1.1em;
    color:black;
    font-size: 82px;
    line-height: 78px;
    text-transform: uppercase;
    display:table;
    margin:auto;
    margin-bottom:0px;


}

.hero-content p {
    font-family: proxima-nova, sans-serif;
    font-weight: 500;
    font-style: normal;
    -webkit-transform: scale(1.3, .8);
        -moz-transform: scale(1.3, .8);
        -o-transform: scale(1.3, .8);
        transform: scale(1.3, .8);
            color: #beaf73;   
    border:3px solid black;
    background:black;
    display:table;
    margin:auto;
    padding: .4em;
    text-transform: uppercase;    
    font-size: 1.7 em;
    margin-top:-10px;
    max-width:500px;
    line-height:35px;
}

.hero-content a {
    border: 3px solid #fff;
    color: #fff;
    text-transform: uppercase;
    font-size: 15px;
    display:table;
    margin:auto;
    padding:10px;
}

.hero-content a:hover {
    background: #e8a348;
}

.hero-content-wrap {
    width: 85%;
    margin: 0 0 0 5%;
    padding-top: 30vh;
    display:flex;
    flex-direction: column;
}

.border-left {
}

.why {
    background: url(/images/shell/seed-texture-short.svg) repeat-x;
    background-position: bottom;
    padding: 80px 0 190px;
    position: relative;
    border-right: 40px solid #e8a348;
    box-sizing: border-box;
}

.why::before {
     content:'';
    width: 40px;
    background: #e8a348;
    height: 100%;
    position: absolute;
    top:0;
}

.why::after {
     content:'';
    width: 40px;
    background: #c2c2c2 ;
    height: 30%;
    position: absolute;
    bottom: 0;
    right: -40px;  
}



.why h2 {
    font-size: 48px;
    line-height: 52px;
    text-transform: uppercase;
    margin-bottom: 20px;
    color:#000
}

.why h5 {
    font-size: 22px;
    margin-bottom: 20px;
}

.why p {
    
}

.offset {
    margin-left: 100px;  
    position: relative;
}

.offset::before {
    content: url(/images/shell/plant-icon.svg);
    display: block;
    width: 70px;
    position: absolute;
    left: -100px;
}

.home.video {
    background-attachment: fixed !important;
    position:relative;
}

.home.video .overlay {
    background: rgba(0,0,0,.5);   
}

.videowrapper {
    margin: 70px 0;   
}

.videowrapper .videoWrapper {
    -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.8);
    box-shadow: 0 0 10px 0 rgba(0,0,0,.8);
}

.home.products {
    background: url(/images/shell/seed-texture-short.svg) repeat-x;
    padding: 180px 0 80px 0; 
}

.home.products .intro {
    text-align: center;   
}

.home.products .intro h3 {
    font-size: 48px;
    line-height: 55px;
    color: #ca4633;
    text-transform: uppercase;
    margin-bottom: 25px;
}

.home.products .intro p {
    font-size: 18px;   
}

.home.products ul {
    margin: 20px 0 0 0;   
}

.home.products ul li {
    display: inline-block;
    *display: inline;
    zoom: 1; 
    width: 32%;
    text-align: center;
    margin: 0 .5%;
    vertical-align: top;
}

.product-desc {
    box-sizing: border-box;
    text-align: center;
    padding: 210px 40px 30px;
    background: #eae8e6;
    margin-top: -200px;
}

.home.products ul li img {
    width: 100%;   
}

.home.products ul li h4 {
    color: #cc8036;
    font-size: 28px;
    text-transform: uppercase;
}

.home.products ul li p {
    font-size: 14px;   
    line-height: 26px;
}

.home.products ul li a {
    display: inline-block;
    padding: 10px 40px;
    background:#ca4633;
    border: 5px solid #fff;
    color: #fff;
    text-transform: uppercase;
    margin-top: -70px !important;
}

.home.products ul li a:hover {
    background: #e8a348;
}

.home.testimonials {
    background: url(/images/shell/seed-texture-full.svg) repeat-x;
    margin-top: 80px;
}

.home-testimonials {
    background: rgba(182, 177, 164, .2);
    margin-top: -40px;
    margin-bottom: 60px;
    box-sizing: border-box;
    padding: 70px 50px;
    position: relative;
}

.testimonial-title {
    text-align: right;
    width: 100%;
}

.testimonial-title h4 {
    color: #cc8036;
    text-transform: uppercase;
    font-size: 23px;
}

.flex ul {
    margin: 0;
}

.flex ul li {
    list-style-type: none;
}

.testimonial-img {
    width: 30%;
}

.testimonial-video {
    width:40%;
}

.testimonial-img,
.testimonial-video {
    display: inline-block;
    zoom: 1;
    vertical-align: middle;
    position: relative;
    left: -100px;
}

.testimonial-img img {
    width: 100%;
}

.testimonial-quote {
    display: inline-block;
    zoom: 1;
    vertical-align: middle;
    width: 70%;
    box-sizing: border-box;
    padding-right: 50px;
}

@media only screen and (min-width: 960px) {
    .testimonial-video-block .testimonial-video {
        width: 40%;
    }
    .testimonial-video-block .testimonial-quote {
        width: 60%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
    .testimonial-video-block .testimonial-video {
        // width: 40%;
        margin-bottom: 40px;
    }
}



.testimonial-quote p {
    font-size: 24px;
    line-height: 36px;
}

.testimonial-quote h6 {
    text-align: right;
    position: relative;
}

.testimonial-quote h6:before {
    content: '';
    width: 50px;
    height: 8px;
    background: #e8a348;
    display: block;
    position: absolute;
    right: 286px;
    top: 10px;
}

.home.tools {
    background: url(/images/new-tools-bkg.jpg) no-repeat bottom;
    background-size: cover;
    padding: 100px 0 300px 0; 
    background-attachment: fixed !important;
}

.home.tools h3 {
    color: #fff;
    text-align: center;
    font-size: 47px;
    text-transform: uppercase;
    margin-bottom: 90px;
    position: relative;
}

.home.tools h3::before {
    content: '';
    width: 29%;
    height: 1px;
    background: #c2c2c2;
    display: block;
    position: absolute;
    left: 6%;
    top: 20px;
}

.home.tools h3::after {
    content: '';
    width: 29%;
    height: 1px;
    background: #c2c2c2;
    display: block;
    position: absolute;
    right: 6%;
    top: 20px;
}

.tool-item {
   
}

.tool-item ul {
    text-align: center;
}

.tool-item ul li {
     background: #ca4633;
    width: 40%;
    display: inline-block;
    zoom: 1;
    margin: 0 50px 70px;
}

.tool-image {
    text-align: center;
    padding: 80px 0;
}

.tool-image img {
    width: 35%;
    display: block;
    margin: 0 auto;
}

.tool-desc {
    box-sizing: border-box;
    padding: 30px 40px 40px;
}

.tool-desc h4 {
    color: #fff;
    text-transform: uppercase;
}

.tool-desc p {
    color: #fff;
}

.tool-desc a {
    color: #fff;
    font-weight: bold;
}

.tool-desc a:hover {
    text-decoration: underline;
}

.home.news {
    background: url(/images/shell/seed-texture-short.svg) repeat-x;
    padding: 140px 0 80px 0;
    -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,.8);
box-shadow: 0 0 15px 0 rgba(0,0,0,.8);
}

.home.news h5 {
    color: #e8a348;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 30px;
}

.all-items {
    border-top: 2px solid #c2c2c2;
    padding-top: 40px;
    margin-top: 40px;
}

.all-items a {
    color: #ca4633;
    border: 3px solid #ca4633;
    padding: 10px 20px;
    display: inline-block;
    text-transform: uppercase;
}

.all-items a:hover {
    color: #fff;
    background: #ca4633;
}

.news-event-item h3 {
    font-size: 26px;
    color:#545759;
    text-transform: none;
}
.news-event-item img {
    width: 100%;
    display: block;
    margin: 0;
}

.news ul {
    margin: 0;
}

.recent-news ul li {
    display: inline-block;
    *display: inline;
    zoom: 1;
    width: 50%;
    box-sizing: border-box;
    padding-right: 20px;
    vertical-align: top;
}

.upcoming-events {
    box-sizing: border-box;
    padding-right: 20px;
}

.meta {
    margin-top: 20px;
}

.meta:before {
    content: '';
    width: 40px;
    height: 5px;
    background: #e8a348;
    display: block;
    margin-bottom: 20px;
}

.meta p {
    margin-bottom: 0;  
    line-height: 15px;
}

.meta h6 {
    font-size: 12px;
    color: #ca4633;
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom: 5px;
}

.news-event-item a {
    color: #ca4633;
    font-weight: bold;
}

.news-event-item a:hover {
    color: #e8a348;
}

@media only screen and (max-width: 1250px) {
    .hero-content h1 {
        color: #000;
        font-size: 55px;
        line-height: 72px;
    }
}

@media only screen and (max-width: 959px) {

.hero-content-wrap h1 {
        color:#000;
    }

   .home.products ul li {
        display: inline-block;
        zoom: 1;
        width: 49%;
        text-align: center;
        margin: 0 .25%;
    }
    
    .testimonial-img {
        vertical-align: top;
        left: -20px;
    }

    .testimonial-video-block .testimonial-video {
        width: 100%;
        left:0;
    }
    .testimonial-video-block .testimonial-quote {
        width: 100%;
    }
    
     .tool-item ul li {
        background: #ca4633;
        width: 60%;
        display: inline-block;
        zoom: 1;
        margin: 0 0 70px;
    }
    
    .home.tools h3::before {
        content: '';
        width: 20%;
        height: 1px;
        background: #c2c2c2;
        display: block;
        position: absolute;
        left: 6%;
        top: 18px;
    }
    
    .home.tools h3::after {
        content: '';
        width: 20%;
        height: 1px;
        background: #c2c2c2;
        display: block;
        position: absolute;
        right: 6%;
        top: 18px;
    }
}

@media only screen and (max-width: 767px) {
    .hero-content-wrap {
        width: 85%;
        margin: 0 0 0 5%;
    }



.hero-content-wrap h1 {
        color:#000;
    }


    .why h2 {
        font-size: 28px;
        line-height: 30px;
        width: 100%;
    }
    .why::before {
         display: none; 
        }
    .why::after {
        display: none; 
    }
    .why {
        border-right: 0;
        background-size: 300%;
    }
    .videoWrapper {
        margin: 40px 0;
    }
    .testimonial-video .videoWrapper {
        margin-top: 0;
        margin-bottom: 0;
    }
    .home.products {
        background-size: 300%;
    }
    .home.products ul li {
        width: 100%;
        display: block;
        margin-bottom: 50px;
    }
    .home.testimonials {
         background-size: 300% !important;
    }
    .home-testimonials {
        background: rgba(255, 255, 255, 1);
        margin-top: 90px !important;
        margin-bottom: 60px;
        box-sizing: border-box;
        padding: 70px 0;
        position: relative;
    }
    .testimonial-title {
        text-align: center !important;
    }
    .testimonial-img,
    .testimonial-video {
        display: block;
        width: 100%;
        left: 0;
    }
    .testimonial-img img {
        width: 100%;
    }
    .testimonial-quote {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding-top: 30px;
        padding-right: 0;
        
    }
    .home.tools h3::before {
        display: none;
    }
    .home.tools h3::after {
       display: none;
    }
    .tool-item ul li {
        background: #ca4633;
        width: 100%;
        display: block;
        margin: 0 0 40px 0;
    }
    .home.news {
        background: url(/images/shell/seed-texture-short.svg) repeat-x;
        padding: 140px 0 80px 0;
        background-size: 300%;
        margin-bottom: 0 !important;
    }
    .news {
        margin-bottom: 50px !important;
    }
    .upcoming-events {
        margin-bottom: 100px !important;
    }
    .recent-news ul li {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding-right: 20px;
        margin-bottom: 60px;
    }

}
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .hero-content {
        background: rgba(0,0,0,.6);
        height: 75vh;
    }
    
    .hero-content h1 {
        color: #000;
        font-size: 38px;
        line-height: 42px;
    }  
    .hero-content-wrap {
        margin: 0 0 0 0 !important;
    }
    
    .testimonial-quote p {
        font-size: 20px;
        line-height: 31px;
    }
    .home-hero .slides li {
        height: 75vh;
    }
    .flexslider {
        margin: 0;
        padding: 0;
        height: 75vh !important;
    }
    .home-hero .slides {
        height: 75vh;
        background: #e1e1e1;
    }
    .hero-content-wrap {
        padding-top: 10vh !important;
    }
    .home.products .intro h3 {
        font-size: 42px;
        line-height: 44px;
    }
    .home.products {
        background: url(/images/shell/seed-texture-short.svg) repeat-x;
        padding: 80px 0 80px 0;
    }
}


@media only screen and (max-width: 736px) {
    .hero-content {
        background: rgba(0,0,0,.6);
        height: 75vh;
    }
    
    .hero-content h1 {
        color: #000;
        font-size: 38px;
        line-height: 42px;
        display:table;
        margin:auto;
    }  

    .hero-content-wrap {
        width: 100%;
        margin: auto;
        padding-top: 40vh;
        display:flex;
        flex-direction: column;
    }


    .hero-content p {
        font-size: 14px;
        line-height: 31px;
        max-width:200px;   
}
    
}


@media only screen and (max-width: 479px) {
    .hero-content {
        background: rgba(0,0,0,.6);
        height: 75vh;
    }
     .hero-content h1 {
        color: #000;
        font-size: 30px;
        line-height: 42px;
            display:table;
    margin:auto;
    margin-bottom:0px;

    } 
    
    .hero-content-wrap {
        width: 85%;
        margin: auto;
        padding-top: 40vh;
        display:flex;
        flex-direction: column;

    }


.hero-content p {
        font-size: 14px;
        line-height: 31px;
        max-width:200px;   
}

    .testimonial-quote p {
        font-size: 20px;
        line-height: 31px;
    }
    .home-hero .slides li {
        height: 75vh;
    }
    .flexslider {
        margin: 0;
        padding: 0;
        height: 75vh !important;
    }
    .home-hero .slides {
        height: 75vh;
        background: #e1e1e1;
    }
    .hero-content-wrap {
        padding-top: 10vh !important;
    }
    .home.products .intro h3 {
        font-size: 42px;
        line-height: 44px;
    }
    .home.products {
        background: url(/images/shell/seed-texture-short.svg) repeat-x;
        padding: 80px 0 80px 0;
    }
}





/* SUBPAGES
==============================================================*/

.sub-hero {
    background-attachment: fixed !important;
    background-position: 50% 50% !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    bottom: 0;
    left: 0;
    position: relative;
    right: 0;
    top: 0;
}

.sub-hero.news-post {
    background-attachment: scroll !important;
}

.sub-hero .hero-content {
    margin: 200px auto !important;
    text-align: center;
    background: transparent !important;
    height: auto !important;
    position: relative;
}

.sub-hero.smaller .hero-content {
    margin:130px auto !important;
    text-align: center;
}


.sub-hero h1 {
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    border: 6px solid black;
    background: rgba(255, 255, 255, 0.6);
    padding: .2em;
    padding-left: 1.1em;
    padding-right: 1.1em;
    color:black;
}
.sub-hero h1::before {
    
}

@media only screen and (max-width: 959px) {
    .sub-hero {
        background-attachment: scroll !important;
        background-size: cover !important;
    }
}

@media only screen and (max-width: 767px) {
    .sub-hero {
        background-position: top right !important;
    }
    
    .sub-hero .hero-content {
        margin: 100px auto !important;
    }
    
    .testimonial-image {
        left: 0 !important;
    }
   
}

@media only screen and (max-width: 767px) {
    .sub-hero {
        background-position: top right !important;
    }
   
}






/* PRODUCTS
==============================================================*/

.products-intro {
    background: url(/images/shell/seed-texture-short.svg) repeat-x;
    padding: 140px 0 100px;
    text-align: center;
}

.products-intro h2 {
    color: #e8a348;
    font-size: 42px;
}

.product-img {
    display: inline-block;
    *display: inline;
    zoom: 1;
    width: 50%;
    vertical-align: middle;
}

.product-img img {
    display: block;
    margin: 0;
    width: 100%;
}

.product-content {
     display: inline-block;
    *display: inline;
    zoom: 1;
    width: 50%;
    vertical-align: middle;
    box-sizing: border-box;
    padding: 50px 100px;
}

.product-content h3 {
    color: #fff;
    font-size: 32px;
}

.product-content p {
    color: #fff;
}

.product-content a {
   font-family: 'kaneda-gothic', sans-serif; 
    text-transform: uppercase;
    font-size: 25px;
    color: #fff;
    -webkit-transition: all 500ms ease-in-out;
-moz-transition: all 500ms ease-in-out;
-ms-transition: all 500ms ease-in-out;
-o-transition: all 500ms ease-in-out;
transition: all 500ms ease-in-out;
}

.product-content a span {
    font-size: 21px;
}

.product-content a:hover {
    opacity: .5;
}

.interstitial {
    text-align: center;
    padding: 80px 0;
    position: relative;
}

.left-pattern {
    background: url(/images/shell/seed-texture-short.svg) no-repeat;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    height: 150px;
}

.right-pattern {
    background: url(/images/shell/seed-texture-short.svg) no-repeat;
    width: 50%;
    position: absolute;
    top: 0;
    right: 0;
    height: 150px;
}

.interstitial h3 {
    color: #e8a348;
    text-transform: uppercase;
    font-size: 32px;
}

.interstitial a {
    border: 3px solid #000;
    padding: 12px 35px;
    text-transform: uppercase;
    display: inline-block;
    color: #000;
}

.interstitial a:hover {
    background: #e8a348;
    color: #fff;
    border: 3px solid #e8a348;
}

/* .float-left {
    float: left;
}

.float-right {
    float: right;
} */

.testimonial-interstitial {
    position: relative;
    padding: 100px 0;
}


.testimonial-container {
     -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
    box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
    position: relative;
    margin-left: 76px !important;
    padding: 50px 0;
}


.testimonial-image {
    width: 300px;
    height: 300px;
    border: 10px solid #fff;
    -webkit-border-radius: 300px;
border-radius: 300px;
    overflow: hidden;
    -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
    display: inline-block;
    *display: inline;
    zoom: 1;
    vertical-align: middle;
    position: relative;
    left: -50px;
}

.testimonial-image img {
    width: 100%;
}

.testimonial-content {
    display: inline-block;
    *display: inline;
    zoom: 1;
    vertical-align: middle;
    box-sizing: border-box;
    width: 600px;
    position: relative;
}

.testimonial-content::before {
    content: url(/images/quote-before.jpg);
    width: 35px;
    height: 25px;
    display: block;
    position: relative;
    left: 48%;
    margin-bottom: 30px;
}

.testimonial-content::after {
    content: url(/images/quote-after.jpg);
    width: 35px;
    height: 25px;
    display: block;
    position: relative;
    left: 48%;
    margin-top: 30px;
}

.testimonial-content p {
    font-size: 26px;
    line-height: 40px;
}

.testimonial-content h6 {
    font-size: 22px;
    line-height: 40px;
    color:#000
}

.call-to-action {
    padding: 80px 0;
    text-align: center;
}

.call-to-action h3 {
    color: #ca4633;
    font-size: 30px;
    text-transform: uppercase;
    width: 50%;
    margin: 0 auto 40px;
}

.call-to-action h2::before {
    content: '';
    width: 150px;
    height: 1px;
    background: #c2c2c2;
    display: block;
    position: absolute;
    left: 0;
    top: 30px;
}

.call-to-action h2::after {
    content: '';
    width: 150px;
    height: 1px;
    background: #c2c2c2;
    display: block;
    position: absolute;
    right: 0;
    top: 30px;
}

.call-to-action a {
    border: 3px solid #000;
    padding: 12px 35px;
    text-transform: uppercase;
    display: inline-block;
    color: #000;
}

.call-to-action a:hover {
    background: #e8a348;
    color: #fff;
    border: 3px solid #e8a348;
}

@media only screen and (max-width: 1259px) {
    .testimonial-image {
        width: 300px;
        height: 300px;
        border: 10px solid #fff;
        -webkit-border-radius: 300px;
        border-radius: 300px;
        overflow: hidden;
        -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
        box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
        display: inline-block;
        zoom: 1;
        vertical-align: middle;
        position: relative;
        left: 50%;
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    .testimonial-image, .testimonial-content {
        position: relative;
        left: 50%;
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    .testimonial-content {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 960px) {  
    .testimonial-container {
        width: 100% !important;
        box-sizing: border-box;
        padding: 50px 100px !important;
        margin-left: 0!important;
    }
    .testimonial-image {
        width: 300px;
        height: 300px;
        border: 10px solid #fff;
        -webkit-border-radius: 300px;
        border-radius: 300px;
        overflow: hidden;
        -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
        box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
        display: inline-block;
        zoom: 1;
        vertical-align: middle;
        position: relative;
        left: 50%;
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

@media only screen and (max-width: 768px) {
    .products-intro {
        background: url(/images/shell/seed-texture-short.svg) repeat-x;
        padding: 140px 0 100px;
        text-align: center;
        background-size: 200%;
    }

     .left-pattern {
        background: url(/images/shell/seed-texture-short.svg) no-repeat;
        width: 100%;
          background-size: 200%;
    }

    .right-pattern {
        background: url(/images/shell/seed-texture-short.svg) no-repeat;
        width: 100%;
        background-size: 200%;
    }
    
    .product-img {
        display: block;
        width: 100%;
    }

    .product-content {
        display: block;
        width: 100%;
        padding: 40px 40px;
    }
    
    .call-to-action h2 {
        color: #ca4633;
        font-size: 30px;
        line-height: 38px;
        text-transform: uppercase;
        width: 60%;
        margin: 0 auto 40px;
    }

    .call-to-action h2::before {
        display: none;
    }

    .call-to-action h2::after {
        display: none;
    }
    
    .testimonial-content {
        display: block;
        vertical-align: middle;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        margin-top: 40px;
        text-align: center;
    }
    
    .testimonial-image {
        width: 300px;
        height: 300px;
        border: 10px solid #fff;
        -webkit-border-radius: 300px;
        border-radius: 300px;
        overflow: hidden;
        -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
        box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
        display: block;
        zoom: 1;
        vertical-align: middle;
        position: relative;
        left: 50% !important;
        -moz-transform: translateX(-50%) !important;
        -webkit-transform: translateX(-50%) !important;
        -o-transform: translateX(-50%) !important;
        -ms-transform: translateX(-50%) !important;
        transform: translateX(-50%) !important;
    }
    
    .testimonial-container {
         -webkit-box-shadow: 0 0 0 0 rgba(0,0,0,0);
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
        position: relative;
        margin-left: 0 !important;
        padding: 50px 10px !important;
    }

}




/* BASE PAGE
==============================================================*/

.main-content {
    background: url(/images/shell/seed-texture-short.svg) repeat-x;
    // padding: 140px 0 100px;
    padding-top: 100px;
}

.leadCopy {
   // padding-top:0px;
   // padding-bottom: 0px;
}

.content-wrapper {
    box-sizing: border-box;
    border-right: 1px solid #e1e1e1;
    padding-right: 70px;
    margin-top:80px;
    @media only screen and (min-width: 768px) {
        margin-bottom: 80px;
    }
}

.content-wrapper h2 {
    color:#e8a348;
    text-transform: uppercase;
}

.sidebar {
    box-sizing: border-box;
    padding-left: 40px;
    margin-top:80px;
}

.sidebar h2 {
    font-size: 28px;
    line-height: 34px;
}

.sidebar h3 {
    font-size: 24px;
    line-height: 30px;
    color:#545759;
    text-transform: none;
}

.sidebar h4 {
    font-size: 20px;
    line-height: 28px;
}

.sidebar h5 {
    font-size: 16px;
    line-height: 22px;

}

.sidebar h6 {
    font-size: 16px;
    line-height: 22px;
}

.sidebar a {
    color: #e8a348;
}

.sidebar a:hover {
    color: #ca4633;
}

.sidebar h4 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 5px;
}

.sidebar h4 a {
    text-transform: uppercase;
    color: #545759;
}

.sidebar h4 a:hover {
    color: #e8a348;
}

.sidebar img {
    width: 100%;
}

.sidebar p {
    line-height: 24px;
    margin-bottom: 10px;
}

.sidebar-item {
    margin-bottom: 60px;
}

.content-testimonial {
    border-top: 3px solid #e1e1e1;
    border-bottom: 3px solid #e1e1e1;
    padding: 30px 0;
    margin: 50px 0 70px;
    position: relative;
}

.content-testimonial .testimonial-image {
    display: inline-block;
    *display: inline;
    zoom: 1;
    vertical-align: middle;
}

.testimonial-insert {
    display: inline-block;
    *display: inline;
    zoom: 1;
    text-align: center;
    vertical-align: middle;
    position: relative;
}

.testimonial-insert p {
    font-size: 26px;
    line-height: 35px;
}

// .testimonial-insert h6 {
//     font-weight: bold;
//     text-transform: uppercase;
// }

.testimonial-insert::before {
    content: url(/images/quote-before.jpg);
    width: 35px;
    height: 25px;
    display: block;
    position: relative;
    left: 48%;
    margin-bottom: 30px;
}

.testimonial-insert::after {
    content: url(/images/quote-after.jpg);
    width: 35px;
    height: 25px;
    display: block;
    position: relative;
    left: 48%;
    margin-top: 30px;
}

.pagination-cta {
    background: url(/images/shell/seed-texture-short.svg) repeat-x;
    padding: 80px 0 100px;
}

.cta-one {
    position: relative;
    display: inline-block;
    *display: inline;
    zoom: 1;
    vertical-align: middle;
    width: 50%;
    text-align: center;
    background: #000;
}

.cta-one img {
    width: 100%;
    display: block;
    margin: 0;
}

.cta-image {
    opacity: .7;
-webkit-transition: all 500ms ease-in-out;
-moz-transition: all 500ms ease-in-out;
-ms-transition: all 500ms ease-in-out;
-o-transition: all 500ms ease-in-out;
transition: all 500ms ease-in-out;
}

.cta-text {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 50%;
    text-shadow: 0 0 10px #383838;
}

.cta-one a {
    color: #fff;
    text-transform: uppercase;
    font-size: 35px;
}

.cta-one:hover .cta-image {
    opacity: .4;
}


@media only screen and (min-width: 768px) and (max-width: 999px) {
    .main-content {
        background: url(/images/shell/seed-texture-short.svg) repeat-x;
        // padding: 100px 0 0;
        padding-top: 75px;
        background-size: 100% !important;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .main-content {
        background: url(/images/shell/seed-texture-short.svg) repeat-x;
        // padding: 100px 0 0;
        padding-top: 75px;
        text-align: center;
        background-size: 150% !important;
    }
    
    .content-wrapper {
        border-right: 0;
        padding: 0 !important;
    }
    
    .sidebar {
        padding-left: 0 !important;
    }
}

@media only screen and (max-width: 479px) {
     .main-content {
        background: url(/images/shell/seed-texture-short.svg) repeat-x;
        // padding: 100px 0 0;
        padding-top: 75px;
        text-align: center;
        background-size: 300% !important;
    }
    
    .content-wrapper {
        border-right: 0;
        padding: 0 !important;
    }
    
    .sidebar {
        padding-left: 0 !important;
    }
      
}

@media only screen and (max-width: 320px) {
     .main-content {
        background: url(/images/shell/seed-texture-short.svg) repeat-x;
        // padding: 100px 0 0;
        padding-top: 75px;
        text-align: center;
        background-size: 300% !important;
    }
    
    .content-wrapper {
        border-right: 0;
        padding: 0 !important;
    }
    
    .sidebar {
        padding-left: 0 !important;
    }
      
}

@media only screen and (max-width: 767px) {
    
    .sidebar {
        border-top: 3px solid #e1e1e1;
        margin-top: 100px !important;
        padding-top: 100px;
    }
   .content-wrapper {
        box-sizing: border-box;
        border-left: 0;
    } 
    
    .pagination-cta {
        background: url(/images/shell/seed-texture-short.svg) repeat-x;
        padding: 70px 0 100px;
        background-size: 300%;
    }
    
    .cta-one {
        position: relative;
        display: block;
        vertical-align: middle;
        width: 100%;
        text-align: center;
        background: #000;
    }
    
    .testimonial-insert {
        display: block;
        width: 100%;
        text-align: center;
        vertical-align: middle;
        position: relative;
    }
    
}

@media only screen and (min-width: 1260px) {
    .content-testimonial .testimonial-insert {
        width: 400px;
    }
}
@media only screen and (max-width: 1259px) {
    .content-testimonial .testimonial-image {
        display: block;
        vertical-align: middle;
        margin: 0 0 50px;
    }
}





/* STORE
==============================================================*/

.store-intro {
    background: url(/images/shell/seed-texture-short.svg) repeat-x;
    padding: 140px 0 0;
    text-align: center;
}

.store-intro p {
    font-size: 22px;
    line-height: 35px;
}

.store-content {
    box-sizing: border-box;
    padding: 30px 100px;
}

.store-content ul {
    margin: 0;
}

.store-content ul li {
    display: inline-block;
    *display: inline;
    zoom: 1;
    width: 33%;
    box-sizing: border-box;
    padding: 0 40px 100px;
    text-align: center;
}

.store-content ul li img {
    width: 80%;
}

.store-content ul li a.main-button {
    border: 3px solid #000;
    padding: 10px 24px;
    display: inline-block;
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
    font-size: 15px;
}

.store-content ul li a.main-button:hover {
    background: #e8a348;
    color: #fff;
    border: 3px solid #e8a348;
}

.store-content h3 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 22px;
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
    .store-intro {
        background: url(/images/shell/seed-texture-short.svg) repeat-x;
        padding: 100px 0 0;
        text-align: center;
        background-size: 100% !important;
    }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
    .store-intro {
        background: url(/images/shell/seed-texture-short.svg) repeat-x;
        padding: 100px 0 0;
        text-align: center;
        background-size: 150% !important;
    }
}

@media only screen and (max-width: 479px) {
     .store-intro {
        background: url(/images/shell/seed-texture-short.svg) repeat-x;
        padding: 100px 0 0;
        text-align: center;
        background-size: 300% !important;
    }
      
}

@media only screen and (max-width: 767px) {
    .store-intro p {
        font-size: 18px;
        line-height: 26px;
    }
    .store-content ul li {
        display: inline-block;
        *display: inline;
        zoom: 1;
        width: 100%;
        box-sizing: border-box;
        padding: 0 0 100px;
        text-align: center;
    }
}

.float-left {
    float: left !important;
}
.float-right {
    float: right !important;
}




/* NEWS FEED
==============================================================*/

.news-feed {
    padding: 70px 0;
    text-align: left;
}
.news-feed h5 {
    color: #e8a348;
    font-size: 30px;
    margin-left: 20px;
    margin-bottom: 30px;
}
.news-feed .recent-news ul {
    display: flex; 
    flex-wrap: wrap;
}
.news-feed .recent-news ul li {
    box-sizing: border-box;
    padding: 20px 20px 40px !important;
    width: 31%;
    margin: 0 1% 20px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
    box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
    vertical-align: top;
    flex-grow: 1;
    width: calc(100% * (1/3));
}
.news-feed .recent-news ul li h3 {
    line-height: 30px;
}
.news-offset {
    margin-top: 30px !important;
    margin-left: 20px;
    margin-right: 20px;
    @media only screen and (min-width:768px) and (max-width: 959px) {
        margin: 0;
    }
}
.news-feed .recent-news ul li .news-image img {
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -ms-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
}
.news-feed .recent-news ul li .news-image a:hover img {
    opacity: .5;
}

/* News single */
.news-container {
    padding-top:50px;

    h2 {
    color: #545759;
    font-size: 30px;
    line-height: 44px;
    margin-bottom: 10px;
    }
}

.events-feed {
    margin-top: 100px;
    border-top: 1px solid #e1e1e1;
    padding-top: 100px;
}

.events-feed ul {
    display: flex; 
    flex-wrap: wrap;
}
.events-feed ul li {
    box-sizing: border-box;
    padding: 20px 20px 40px !important;
    width: 31%;
    margin: 0 1% 20px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
    box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
    vertical-align: top;
    flex-grow: 1;
    width: calc(100% * (1/4));
    display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .news-feed .recent-news ul li {
        padding: 20px 20px 40px !important;
        width: 48% !important;
        margin: 0 1% 20px;
    } 
    
    .events-feed ul li {
        padding: 20px 20px 40px !important;
        width: 48% !important;
        margin: 0 1% 20px;
    } 
}

@media only screen and (max-width: 767px) {
    .news-feed .news ul {
        display: block;
    }
    .news-feed .recent-news ul li {
        padding: 20px 20px 40px !important;
        width: 100% !important;
        margin: 0 0 20px;
        -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0);
        box-shadow: 0 0 10px 0 rgba(0,0,0,0);
        display: block;
        border-bottom: 4px solid #e1e1e1;
    } 
    .news-feed .recent-news ul li:last-child {
        border-bottom: 0;
    }
    
    .events-feed ul {
        display: block;
    }
    .events-feed ul li {
        padding: 20px 20px 40px !important;
        width: 100% !important;
        margin: 0 0 20px;
        -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0);
        box-shadow: 0 0 10px 0 rgba(0,0,0,0);
        display: block;
        border-bottom: 4px solid #e1e1e1;
    } 
    .events-feed ul li:last-child {
        border-bottom: 0;
    }
}



/* AGRONOMY
==============================================================*/

.additonal-content {
    box-sizing: border-box;
    margin-bottom: -80px !important;
    padding: 80px 0 0;
    position: relative;
    text-align: center;
}

.additonal-content h2 {
    color: #e8a348;
    font-size: 45px;
}

.additonal-content iframe {
    margin-top: 30px;
    border: 0 !important;
}

.additonal-content-align-left {
    
    text-align: left;
}

/* ABOUT
==============================================================*/

.about-feeds {
    padding: 0 0 60px;
    text-align: center;
    
}

.about-feeds h2 {
    background: url(/images/shell/seed-texture.svg) #ca4633;
    color: #fff;
    font-size: 48px;
    margin-bottom: 60px;
    padding: 100px 0;
    width: 100%;
}

.team-wrapper {
    margin-bottom: 130px;
}

.team-wrapper h3 {
    font-weight: bold;
    margin-bottom: 35px;
    text-transform: uppercase;
    position: relative;
}

.team-wrapper h3::before {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    width: 100px;
    background: #545759;
    top: 15px;
    left: 350px;
}

.team-wrapper h3::after{
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    width: 100px;
    background: #545759;
    top: 15px;
    right: 350px;
}

.team-wrapper ul {
    margin: 0;
}

.team-wrapper ul li {
    box-sizing: border-box;
    display: inline-block;
    *display: inline;
    zoom: 1;
    width: 25%;
    vertical-align: top;
    margin-bottom: 70px;
}

.member-image {
    width: 250px;
    margin: 0 auto 15px;
    -webkit-border-radius: 125px;
    border-radius: 125px;
    overflow: hidden;
}

.member-image img {
    width: 100%;
    display: block;
    margin: 0;
}

.team-wrapper ul li h4 {
    color: #e8a348;
    font-weight: bold;
    margin-bottom: 0;
}

.team-wrapper ul li p.staff_title {
    margin-bottom: 0;
    font-weight: bold;
}

.team-wrapper ul li p {
    margin-bottom: 0;
    font-weight: normal;
}

.team-wrapper ul li p a {
    font-weight: bold;
    color: #e8a348;
}

// .team-wrapper ul li phone {
    
// }

.content-wrapper {
    .team-wrapper ul li {
        width: 50%;
    }
    .team-wrapper h3 {
        color: #545759;
        font-size: 26px;
    }
    .team-wrapper h3::before {
        left: 125px;
    }
    .team-wrapper h3::after {
        right: 125px;
    }
}

.about-financing {
    position: relative;
    text-align: center;
    background-position: 50% 50% !important;
    background-attachment: fixed !important;
}

.overlay-red {
   position: absolute;
    background: rgba(202,70,51,.9) !important;
    width: 100%;
    height: 100%;
}

.cta_content {
    padding: 120px 0;
}

.cta_content .s1 {
    font-size: 52px;
    color: #fff;
    margin-bottom: 40px;
}


.cta_content h2 {
    color: #fff;
    margin-bottom: 40px;
}

.cta_content h3 {
    color: #fff;
    margin-bottom: 30px;
}

.cta_content h4 {
    color: #fff;
    margin-bottom: 30px;
}

.cta_content h5 {
    color: #fff;
    margin-bottom: 30px;
}

.cta_content h6 {
    color: #fff;
}

.cta_content p {
    color: #fff;
    margin-bottom: 30px;
}

.cta_content a {
    display: inline-block;
    border: 3px solid #000;
    padding: 15px 30px;
    color: #000;
    text-transform: uppercase;
    font-size: 20px;
    background: RGBA(255,255,255, .5);
}

@media only screen and (min-width: 768px) and (max-width: 1259px) {
    .team-wrapper h3::before {
        content: '';
        position: absolute;
        display: block;
        width: 100px;
        top: 15px;
        left: 100px;
    }

    .team-wrapper h3::after{
        content: '';
        position: absolute;
        display: block;
        width: 100px;
        top: 15px;
        right: 100px;
    }
    
     .team-wrapper ul li {
        box-sizing: block;
        width: 50%;
        vertical-align: top;
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 1259px) {
    .content-wrapper {
        .team-wrapper ul li {
            width: 100%;
        }
        .team-wrapper h3::before {
            left: 25px;
        }
        .team-wrapper h3::after {
            right: 25px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .about-feeds h2 {
        background: url(/images/shell/seed-texture.svg) #ca4633;
        background-size: 250%;
        color: #fff;
        font-size: 32px;
        width: 100%;
    }
    
    .team-wrapper h3 {
        margin-bottom: 35px;
    }

    .team-wrapper h3::before {
        content: '';
        position: absolute;
        display: block;
        width: 100px;
        top: 15px;
        left: 350px;
    }

    .team-wrapper h3::after{
        content: '';
        position: absolute;
        display: block;
        width: 100px;
        top: 15px;
        right: 350px;
    }
    
    .team-wrapper ul li {
        box-sizing: block;
        width: 100%;
        vertical-align: top;
        margin-bottom: 50px;
    }

    .content-wrapper {
        .team-wrapper h3::before {
            left: 25px;
        }
        .team-wrapper h3::after {
            right: 25px;
        }
    }
}

@media only screen and (max-width: 479px) {
    .content-wrapper {
        .team-wrapper h3::before {
            left: -250px;
        }
        .team-wrapper h3::after {
            right: -250px;
        }
    }
}

.news-post h1 {
    font-size: 30px;
    line-height: 36px;
}

.back-to-news {
    border-top: 1px solid #e1e1e1;
    padding-top: 50px;
    padding-bottom: 30px;
}
.back-to-news a {
    color: #e8a348;
    font-weight: bold;
}
.back-to-news a:hover {
    color: #4f4e4d;
}

.newsletters {
    
    
}

.newsletters ul {
    margin: 0 0 0 15px;
    padding: 0;
}

.newsletters ul li {
    list-style-type: none;
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-bottom: 1px solid #e1e1e1;
}

.newsletter-wrapper {
    position: relative;
}
.newsletter-icon {
    display: inline-block;
    vertical-align: middle;
    width: 10%;
}
.newsletter-icon img {
    width: 100%;
    display: block;
    margin: 0;
}
.newsletter-title {
    display: inline-block;
    vertical-align: middle;
    width: 90%;
    box-sizing: border-box;
    padding-left: 20px;
}
.newsletter-title h3 {
    margin-bottom: 0;
    line-height: 22px;
    text-transform: none;
}
.newsletter-title h3 a {
    font-size: 22px;
    line-height: 24px !important;
}


.search-listing {
    padding-top:80px;
    padding-bottom:80px;
}
.search-entry {
    text-align: left!important;
}
.four-oh-four-header {
    padding-top:80px;
}
.four-oh-four-message {
    padding-top:80px;
}

.pagination {
    margin:30px 0 0;
    > li {
        display: inline-block;
        list-style: none;
        margin-bottom: 0;
        &.disabled {
            padding:5px 8px;
        }
    }
    > li > a {
        display: block;
        padding:5px 8px;
    }
}


/* Smaller than Medium Desktop */
@media only screen and (max-width: 1179px) {}
/* Medium Desktop */
@media only screen and (min-width: 1000px) and (max-width: 1179px) {}
/* Smaller than 960 (devices and browsers) */
@media only screen and (max-width: 999px) {}
/* Tablet Portrait size to standard 960 (devices and browsers) */
@media only screen and (min-width: 768px) and (max-width: 999px) {}
/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {}
/* Mobile Landscape Size to Tablet Portrait (devices and browsers) */
@media only screen and (min-width: 480px) and (max-width: 767px) {}
/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
@media only screen and (max-width: 479px) {}
