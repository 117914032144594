@import "seedware-fonts";
@import url("https://use.typekit.net/qyc4rrc.css");
  
$brand-red: rgb(202,70,51);
$border-color: #ddd;
$color-corn: #e8a348;
$color-soy: #809a47;
$color-alfalfa: #603d66;
$designation-breakpoint: 1260px;
$trait-breakpoint: 1260px;

.table-responsive {
  width: 100%;
  overflow-x: auto;
  @media (min-width: 360px) {
    width: 120%;
    margin-left: -10%;
  }
  @media (min-width: 527px) {
    width: 110%;
    margin-left: -5%;
  }
  @media (min-width: $designation-breakpoint) {
    width: 100%;
    margin-left: 0;
  }
}
.table-seedware-products {
  width: 100%;


  //border-top: 1px solid $border-color;
  thead {
    th {
      font-weight: bold;
      font-size: 0.9em;
      padding: 0;
      @media (min-width: $designation-breakpoint) {
        font-size: 1.2em;
      }
      position: relative;
      span {
        position: absolute;
        right: 0;
      }
    }
  }
  //.seedware-product-designation-new {
  //  padding-right: 0;
  //  padding-left: 0;
  //  width: 40px;
  //  img {
  //    display: block;
  //    margin-right: 0;
  //  }
  //}
  .seedware-product-designation {
    color: white;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    padding-left: 6px;
    padding-right: 0;
    @media (min-width: $designation-breakpoint) {
      padding-right: 14px;
    }
    width: 50px;
    span {
      color: white;
      display: block;
      line-height: 1;
    }

    &.new {
      background: $brand-red;
      &:before {
        content: '';
        position: absolute;
        left: 100%;
        bottom: 0;
        height: 100%;
        width: 20px;
        background: white;
        transform-origin: top left;
        transform: skewX(-20.5deg);
      }
    }
  }
  tr {
    th, td {
      border-bottom: 1px solid $border-color;
      text-align: left;
      > a {
        //background: blue;
        display: block;
        //height: 100%;
        overflow: hidden;
        &:hover {
          color: inherit;
        }
      }
    }

    .text-right {
      text-align: right;
    }
  }
  td, th {
    vertical-align: middle;
    padding: 0;
    >a {
      padding: 6px 4px;
    }
  }
  .hybrid-logo {
    text-align: right;
    img {
      max-height: 20px;
      @media (min-width: 960px) {
        max-height: 29px;
      }
      width: auto;
      height: auto;
      max-width: 100%;
      //display: block;
      float: right;
    }
  }
  .variety {
    padding-left: 4px;
    padding-right: 2px;
    @media (min-width: $designation-breakpoint) {
      padding-right: 14px;
    }
    @media (min-width: $designation-breakpoint) {
      padding-left: 0;
    }
    .new {
      display: none;
      color: $brand-red;
      font-size: 0.5em;
      position: relative;
      left: -2px;
      text-transform: uppercase;
      font-weight: bold;
      @media (max-width: $designation-breakpoint) {
        display: inline;
      }
    }
  }
  .description, .expand {
    //width: 99%;

  }

  .description {
    font-size: 0.9em;
    @media (min-width: 960px) {
      font-size: 1em;
    }
    width: 100%; /* Extend the cell as much as possible */
    max-width: 0; /* Avoid resizing beyond table width */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    //max-width: 200px;
    a {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      width: 100%;
    }
  }
  .description, .trait {
    font-size: 0.9em;
    @media (min-width: 960px) {
      font-size: 1em;
    }
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    //max-width: 200px;
    a {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      width: 100%;
    }
  }
  .trait, .paulsen-seedware-hidden-mobile {
    @media (max-width: $trait-breakpoint) {
      display: none;
    }

  }
  .variety, .maturity {
    font-size: 0.9em;
    @media (min-width: 960px) {
      font-size: 1.25em;
    }
    font-weight: bold;
    white-space: nowrap;
    sup {
      font-size: 0.5em;
      top: -0.8em;
    }
  }
  .maturity {
    text-align: right;
  }
}

.seedware-product {
  .product-heading {
    .trait-logo {
      max-height: 35px;
      width: auto;
    }
    .product-title {
      font-size: 2.2em;
      margin: 0;
      line-height: 1.2;
      @media (min-width: 600px) {
        padding-bottom: 30px;
      }
      @media (min-width: $designation-breakpoint) {
        padding-right: 14px;
      }

    }
    @media (min-width: 600px) {
      overflow: hidden;
      position: relative;
      .product-title {
        font-size: 4em;
      }
      .trait-logo {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    .seedware-product-designation.new {
      background: $brand-red;
      color: white;
      text-transform: uppercase;
      text-align: center;
      //width: 80px;
      font-size: 16px;
      line-height: 1;
      padding: 8px;
      display: inline-block;
      position: relative;
      top: -1rem;
      font-weight: 900;
      border: 4px solid white;
      border-radius: 4px;
      box-shadow: -2px 2px 3px rgba(black, 0.25);
      transform: rotate(5deg);
      a {
        color: white !important;
      }
      &:before {
        content: "";
        display: block;
        width: 18px;
        height: 12px;
        background: white;
        position: absolute;
        top: -4px;
        right: -4px;
        border-radius: 0 4px;
        box-shadow: -2px 2px 2px rgba(black, 0.25);

      }

      //top: -12px;

    }
  }
  .attribute-groups {
    padding-bottom: 2em;
    //display: grid;
    //grid-template-columns: 1fr 1fr 1fr;
    @media (min-width: 960px) {
      columns: 2;
    }
    @media (min-width: 980px) {
      //columns: 3;
    }
    column-gap: 2em;

    .attribute-group {
      break-inside: avoid;
      margin-bottom: 2em;
    }
    .attribute-group-heading {
      background-color: $color-corn;
      padding: 10px 12px;
      text-align: center;
    }
    .attribute-group-title {
      color: white;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 1.1em;
      line-height: 1;
      font-weight: bold;
    }
    table {
      margin-top: 0 !important;
    }
    .attribute-group-group_suggested_zone {
      img {
        display: block;
        margin-top: 4px;
      }
    }

  }
  .table-attribute-group {
    width: 100%;


    tbody > tr:nth-of-type(odd) {
      background-color: #f9f9f9;
    }
    tbody {
      //border-top: 1px solid $border-color;

    }
    tr {
      //border-bottom: 1px solid $border-color;
    }
    th, td {
      padding: 4px 12px;
      vertical-align: middle;
      border: 1px solid $border-color;
    }
    th {
      text-align: left;
      font-weight: bold;
      font-size: 0.9em;
    }
    td {
      text-align: right;
      font-size: 1.1em;
    }
  }
}

.seedware-product {
  .product-meta {
    .maturity {
      font-size: 2.1em;
      margin: 0;
      font-weight: normal;

    }
    margin-bottom: 20px;
    @media (min-width: 600px) {

      margin-top: 20px;
    }
    @media (min-width: 400px) {
      overflow: hidden;
      .download-tech-sheet {
        position: relative;
        top: 0.3rem;
        float: right;
      }

      .maturity {
        line-height: 1.1;
        float: left;
        strong {
          font-weight: 600;
        }
      }
    }


  }
  .lead {
    font-size: 1.1em;
    line-height: 1.4em;
  }
}

.seedware-product-Soybeans,
.seedware-product-Soybean {
  .attribute-groups {
    .attribute-group-heading {
      background-color: $color-soy;
    }

  }
}
.seedware-product-Alfalfa {
  .attribute-groups {
    .attribute-group-heading {
      background-color: $color-alfalfa;
    }

  }
}
.seedware-rec-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
.seedware-rec-card {
  border: 1px solid $border-color;
  img {
    max-height: 45px;
  }
}
.seedware-rec-card-body {
  padding: 10px;
  font-size: 0.85em;
}
.seedware-rec-card-heading {
  padding: 6px 12px;
  background: $color-corn;
  border-bottom: 1px solid $border-color;
  text-align: center;
  .seedware-rec-card-title {
    color: white;
    margin: 0;
    font-size: 1.3em;
  }
}

.seedware-rec-cards-soy {
  .seedware-rec-card-heading {
    background: $color-soy;
  }
}

tr.error {
  background: rgba(darkred, 0.125);
}


.tech-sheet-link {
  font-size: 1.25em;
}

table.table-debug {
  margin: 0 auto !important;
  caption {
    margin-top: 90px;
    font-weight: bold;
    font-size: 1.4em;
  }
}

.debug-product-links {
  p {
    margin-bottom: 0;
  }
  columns: 3;
  @media (min-width: 715px) {
    columns: 6;
  }
}

.post-edit {
  display: none;
}



.heading-nearby-dealers {
  margin-bottom: 0;
  font-size: 1.5em;
}

.text-right {
  text-align: right;
}

.menu-products-container {
  .menu {
    list-style-type: none;
    margin-left: 0;

  }
  .menu-item {
    margin-bottom: 0;
    a {
      &:after {
        content: ' »';
      }
    }
  }
  .current-menu-item {
    font-weight: bold;
    a {
      &:after {
        content: '';
      }
    }
  }
}