@font-face {
  font-family: 'icomoon';
  src:  url('webfont/seedware/icomoon.eot?m40u49');
  src:  url('webfont/seedware/icomoon.eot?m40u49#iefix') format('embedded-opentype'),
  url('webfont/seedware/icomoon.ttf?m40u49') format('truetype'),
  url('webfont/seedware/icomoon.woff?m40u49') format('woff'),
  url('webfont/seedware/icomoon.svg?m40u49#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cloud-download:before {
  content: "\e9c2";
}
.icon-download3:before {
  content: "\e9c7";
}
