.logged-in {
  margin-top:37px;
}
.admin-bar {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  background-color:#343a40;
  color:#f5f5f5;
  font-size:13px;
  height:37px;
  position:fixed;
  top:0;
  right: 0;
  left: 0;
  z-index: 10001;
  .admin-bar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    margin:0;
    list-style: none;
    > li {
      margin:0;
    }
  }
  .ab-item {
    padding: 3px 16px;
    &.ab-dropdown-toggle {
      padding-right: 0.875rem;
      &::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .2em;
        content: "";
        border-top: .375em solid;
        border-right: .375em solid transparent;
        border-bottom: 0;
        border-left: .375em solid transparent;
        color: #888888;
        margin-left: 0.5rem;
      }
    }
  }
  .ab-dropdown-menu {
    position: absolute;
    background-color: #343a40;
    border-radius: .25rem;
    display: none;
    top: 40px;
    left: 5px;
    padding: .5rem 0;
    min-width: 10rem;
    text-align: left;
    z-index: 1031;
    .ab-dropdown-item {
      border:0;
      display: block;
      padding: .25rem 1rem;
      &:first-child {
        border-top-left-radius: calc(.25rem - 1px);
        border-top-right-radius: calc(.25rem - 1px);
      }
      &:last-child {
        border-bottom-right-radius: calc(.25rem - 1px);
        border-bottom-left-radius: calc(.25rem - 1px);
      }
    }
  }
  a {
    color:#f5f5f5;
    border-right: 1px solid #202020;
    display: block;
    &:hover, &:focus {
      color:#f5f5f5;
      background-color: #202020;
    }
  }
  .ab-show {
    display: block;
  }
}