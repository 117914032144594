/* Table of Content
==================================================
   #Reset & Basics
   #Basic Styles
   #Site Styles
   #Typography
   #Links
   #Lists
   #Images
   #Buttons
   #Forms
   #Misc */


/* #Reset & Basics (Inspired by E. Meyers)
================================================== */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
   margin: 0;
   padding: 0;
   border: 0;
   font-size: 100%;
   font: inherit;
   vertical-align: baseline; }
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
   display: block; }
body {
   line-height: 1; }
ol, ul {
   list-style: none; }
blockquote, q {
   quotes: none; }
blockquote:before, blockquote:after,
q:before, q:after {
   content: '';
   content: none; }
table {
   border-collapse: collapse;
   border-spacing: 0; }


/* #Basic Styles
================================================== */
body {
   background: #fff;
   font: 16px/21px 'proxima-nova', sans-serif;
    font-weight: 400;
   color: #545759;
   -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
   -webkit-text-size-adjust: 100%;
}

sub, sup {
  font-size: 30%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

.gold-bkg {
    background: #e8a348;
}

.red-bkg {
    background: #ca4633;
}

.green-bkg {
    background: #809a47;
}

.brown-bkg {
    background: #b1702a;
}

.purple-bkg {
    background: #603d66;
}

.gray-bkg {
    background: url(/images/gray-bkg.jpg);
    background-size: cover;
}


/* #Typography
================================================== */
h1, h2, h3, h4, h5, h6 {
   font-family: kaneda-gothic, sans-serif;
   font-weight: 400;
   font-style: normal;

}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { font-weight: inherit; }

h1 { font-size: 44px; line-height: 56px; margin-bottom: 10px; }
h2 { font-size: 30px; line-height: 44px; margin-bottom: 10px; }
h3 { font-size: 26px; line-height: 36px; margin-bottom: 10px; }
h4 { font-size: 24px; line-height: 40px; margin-bottom: 10px; }
h5 { font-size: 20px; line-height: 30px; margin-bottom: 5px; }
h6 { font-size: 20px; line-height: 24px; margin-bottom: 0; }

.s1 { font-size: 44px; line-height: 56px; margin-bottom: 10px; }
.s2 { font-size: 30px; line-height: 44px; margin-bottom: 10px; }
.s3 { font-size: 26px; line-height: 36px; margin-bottom: 10px; }
.s4 { font-size: 24px; line-height: 40px; margin-bottom: 10px; }
.s5 { font-size: 20px; line-height: 30px; margin-bottom: 5px; }
.s6 { font-size: 16px; line-height: 24px; margin-bottom: 0; }


p { margin: 0 0 24px 0; }
p img { margin: 0; }

p.lead { font-size: 18px; line-height: 26px;  }
p.small { font-size: 12px; line-height: 22px; }
p.legal { font-size: 12px; line-height: 18px; margin-bottom: 20px; }

em, i { font-style: italic; }
strong, b { font-weight: bold; }
small { font-size: 80%; }
.normal { font-weight: normal !important; }

/* Blockquotes  */
blockquote {
   background: #f5f5f5;
   border: none;
   font-family: 'GothamRounded', sans-serif;
   font-size: 20px;
   line-height: 34px;
   padding: 30px;
}
blockquote p {
   font-size: 20px;
   line-height: 34px;
}
blockquote > *:last-child {
   margin-bottom: 0;
}
blockquote > p:first-child:before {
   content: "\201c";
   font-size: 40px;
   left: -5px;
   line-height: 34px;
   position: relative;
   top: 10px;
}
blockquote > p:last-child:after {
   content: "\201d";
}


hr {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: #cdcdcd;
    border-image: none;
    border-style: solid;
    border-width: 1px 0 0;
    clear: both;
    height: 0;
    margin: 40px 0 40px;
}
hr.pattern {
    background: url(/images/shell/pattern.jpg);
    height: 70px;
    width: 100%;
    margin: -30px 0 -40px;
    border: 0 !important;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    position: relative;
    z-index: 9999;
}
abbr, acronym {
    border-bottom: 1px dotted;
}
cite {
    font-style: italic;
}
code, key, tt, var {
    background: #f5f5f5 none repeat scroll 0 0;
    border: 1px solid #e1e1e1;
    display: inline-block;
    font-family: "Courier New",Courier,monospace;
    padding-left: 5px;
    padding-right: 5px;
}
pre {
    background: #f5f5f5 none repeat scroll 0 0;
    border: 1px solid #cdcdcd;
    font-family: "Courier New",Courier,monospace;
    margin-bottom: 20px;
    overflow-x: scroll;
    padding: 20px;
}
q::before {
    content: "“";
}
q::after {
    content: "”";
}
sup {
    font-size: 0.75em;
    position: relative;
    top: -0.45em;
}
sub {
    bottom: -0.45em;
    font-size: 0.75em;
    position: relative;
}



.nowrap { white-space: nowrap; }


.alignright {
   float:right;
   margin-left: 24px;
   margin-bottom: 24px;
}
.alignleft {
   float:left;
   margin-right: 24px;
   margin-bottom: 24px;
}
.aligncenter {
   display: block;
   margin: 0 auto 24px auto;
}


/* #Links
================================================== */
a, a:visited { 
   color: #545759; 
   text-decoration: none; 
   outline: 0; 
   -webkit-transition: background 200ms ease-in-out 0ms, color 200ms ease-in-out 0ms;
   -moz-transition: background 200ms ease-in-out 0ms, color 200ms ease-in-out 0ms;
   -ms-transition: background 200ms ease-in-out 0ms, color 200ms ease-in-out 0ms;
   -o-transition: background 200ms ease-in-out 0ms, color 200ms ease-in-out 0ms;
   transition: background 200ms ease-in-out 0ms, color 200ms ease-in-out 0ms;
}
a:hover, a:focus { color: #ca4633; }
p a, p a:visited { line-height: inherit; }


/* #Lists
================================================== */
ul, ol {
    margin-bottom: 20px;
}
ul {
    list-style-position: outside;
   list-style-type: disc;
}
ol {
    list-style-position: outside;
   list-style-type: decimal;
}
ol, ul {
    margin-left: 30px;
}
ul ul, ul ol, ol ol, ol ul {
    margin: 5px 0 5px 25px;
}
ul ul li, ul ol li, ol ol li, ol ul li {
    margin-bottom: 10px;
}
li {
    margin-bottom: 10px;
}
li p {
}
ul li ul li {
    list-style-type: circle;
}
ul li ul li ul li {
    list-style-type: square;
}
ol li ol li {
    list-style-type: lower-alpha;
}
ol li ol li ol li {
    list-style-type: lower-roman;
}

.tight-list li {
   margin-bottom: 8px;
}

/* #Images
================================================== */

img.scale-with-grid {
max-width: 100%;
height: auto; }


/* #Buttons
================================================== */

button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
   background: transparent;
   border: 2px solid #e8a348;
   -webkit-border-radius: 9px;
   border-radius: 7px;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
   color: #fff;
   cursor: pointer;
   display: inline-block;
    background: #e8a348;
   font-family: 'GothamRounded', serif;
    font-weight: bold;
    font-size: 17px;
    text-transform: uppercase;
   padding: 20px;
   text-align: center;
   text-decoration: none;
   -webkit-transition: background 200ms ease-in-out 0ms, border-color 200ms ease-in-out 0ms, color 200ms ease-in-out 0ms;
   -moz-transition: background 200ms ease-in-out 0ms, border-color 200ms ease-in-out 0ms, color 200ms ease-in-out 0ms;
   -ms-transition: background 200ms ease-in-out 0ms, border-color 200ms ease-in-out 0ms, color 200ms ease-in-out 0ms;
   -o-transition: background 200ms ease-in-out 0ms, border-color 200ms ease-in-out 0ms, color 200ms ease-in-out 0ms;
   transition: background 200ms ease-in-out 0ms, border-color 200ms ease-in-out 0ms, color 200ms ease-in-out 0ms;
   -webkit-appearance: none;
}

button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover {
   background: #ca4633;
   border-color: #ca4633;
   color: #fff;
}

.borderless-button {
   border: none;
   -webkit-border-radius: 0;
   border-radius: 0;
   font-size: 15px;
   line-height: 16px;
   padding: 0;
}
.borderless-button > span {
   font-size: 18px;
   margin-left: 4px;
   position: relative;
   top: -1px;
   vertical-align: middle;
}
.borderless-button:hover {
   background: transparent;
   color: #cf9b64;
}


.button.full-width,
button.full-width,
input[type="submit"].full-width,
input[type="reset"].full-width,
input[type="button"].full-width {
   -moz-box-sizing: border-box;
   box-sizing: border-box;
   width: 100%;
   text-align: center; 
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
border: 0;
padding: 0;
}

input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
   color:  #b2b2b2;
   opacity: 1 !important;
}
input[type="text"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
textarea:-moz-placeholder { /* Firefox 18- */
   color:  #3a3a3a;  
   opacity: 1 !important;
}
input[type="text"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
textarea::-moz-placeholder {  /* Firefox 19+ */
   color:  #3a3a3a; 
   opacity: 1 !important;
}
input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {  
   color:  #3a3a3a; 
   opacity: 1 !important;
}

/* #Forms
================================================== */

form {
   margin-bottom: 20px;
}
fieldset {
   margin-bottom: 20px;
}
input[type="search"], input[type="text"], input[type="password"], input[type="email"], input[type="tel"], textarea, select {
   background: #fff none repeat scroll 0 0;
   border: 2px solid #b7b7b7;
   -webkit-border-radius: 9px;
   border-radius: 9px;
   box-sizing: border-box;
   display: block;
   margin: 0 0 10px;
   max-width: 100%;
   outline: medium none;
   padding: 20px;
   -webkit-transition: border-color 200ms ease-in-out 0ms, border-color 200ms ease-in-out 0ms, color 200ms ease-in-out 0ms;
   -moz-transition: border-color 200ms ease-in-out 0ms, color 200ms ease-in-out 0ms;
   -ms-transition: border-color 200ms ease-in-out 0ms, color 200ms ease-in-out 0ms;
   -o-transition: border-color 200ms ease-in-out 0ms, color 200ms ease-in-out 0ms;
   transition: border-color 200ms ease-in-out 0ms, color 200ms ease-in-out 0ms;
   width: 100%;
    font-size: 15px;
    font-family: 'GothamRounded', sans-serif;
    color: #000;
}
select {
   padding: 0;
}
input[type="search"]:focus,
input[type="text"]:focus, 
input[type="password"]:focus, 
input[type="email"]:focus, 
textarea:focus {
   border: 2px solid #FF7C1A;
}
textarea {
   height: 150px;
   min-height: 150px;
}
label, legend {
   display: block;
   font-size: 16px;
   font-weight: bold;
}
input[type="checkbox"] {
   display: inline;
}
label span, legend span {
   color: #444;
   font-size: 13px;
   font-weight: normal;
}




@font-face {
    font-family: 'GothamRounded';
    font-weight: bold;
    src: url('webfont/gothamrnd-bold.eot');
    src: url('webfont/gothamrnd-bold.eot') format('embedded-opentype'),
         url('webfont/gothamrnd-bold.woff') format('woff'),
         url('webfont/gothamrnd-bold.ttf') format('truetype'),
         url('webfont/gothamrnd-bold.svg#GothamRoundedBold') format('svg');
}

@font-face {
    font-family: 'GothamRounded';
    font-weight: normal;
    src: url('webfont/gothamrnd-book.eot');
    src: url('webfont/gothamrnd-book.eot') format('embedded-opentype'),
         url('webfont/gothamrnd-book.woff') format('woff'),
         url('webfont/gothamrnd-book.ttf') format('truetype'),
         url('webfont/gothamrnd-book.svg#GothamRoundedBook') format('svg');
}
@font-face {
    font-family: 'GothamRounded';
    font-weight: 500;
    src: url('webfont/gothamrnd-medium.eot');
    src: url('webfont/gothamrnd-medium.eot') format('embedded-opentype'),
         url('webfont/gothamrnd-medium.woff') format('woff'),
         url('webfont/gothamrnd-medium.ttf') format('truetype'),
         url('webfont/gothamrnd-medium.svg#GothamRoundedMedium') format('svg');
}

